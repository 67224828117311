/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import Product from '../../models/product';
import { api } from '../../utils/api';
import constants from '../../utils/constants';
import Link from 'next/link';

const CustomFooter = (props: any): JSX.Element => {
    const source = axios.CancelToken.source();
    const [lastFourProducts, setLastFourProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const downloadLastFourProducts = () => {
        api
            .get('/products', {
                params: {
                    sortType: constants.SORT_TYPE.DESC,
                    sortSelect: 'createdAt'
                },
                cancelToken: source.token
            })
            .then((res) => setLastFourProducts(res.data.products))
            .catch();
    };

    const getAllResources = async (): Promise<void> => {
        await downloadLastFourProducts();   
        setIsLoading(false);
    };

    useEffect(() => {
        getAllResources();
    }, []);

    const showProducts = lastFourProducts.map(prod => (
        <div
            className="navfooter__item navfooter__item_custom"
            key={prod.id}
        >
            <Link href={`/products/${prod.id}`} passHref>
                <a className="navfooter__link">{prod.name}</a>
            </Link>
        </div>
    ));

    return (
        <footer className="footer">
            <div className="container footer__container">
                <div className="footer__content">
                    <div className="footer__nav footer__nav_custom">
                        <div className="navfooter">
                            <div>
                                <div className="navfooter__item navfooter__item-title navfooter__item_offset">
                                    Products
                                </div>
                                <div className="footer__line-title"></div>
                                <Spin spinning={isLoading}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {showProducts}
                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </div>
                    <div className="footer__extra">
                        <div className="navfooter navfooter_alt">
                            <div className="navfooter__col">
                                <div className="navfooter__item navfooter__item-title navfooter__item_offset">
                                    Company
                                </div>
                                <div className="footer__line-title"></div>
                                <div className="navfooter__item navfooter__item_offset_small">
                                    <Link href="/about-us" passHref>
                                        <a className="navfooter__link">About Us</a>
                                    </Link>
                                </div>
                                <div className="navfooter__item">
                                    <Link href="/blog" passHref>
                                        <a className="navfooter__link">Blog</a>
                                    </Link>
                                </div>
                                {/* <div className="navfooter__item">
                                    <Link className="navfooter__link" to="/press-center">
                                        Press center
                                    </Link>
                                </div> */}
                            </div>
                            <div className="navfooter__col">
                                <div className="navfooter__item navfooter__item-title navfooter__item_offset">
                                    Help
                                </div>
                                <div className="footer__line-title"></div>
                                <div className="navfooter__item">
                                    <Link href="/contact-us" passHref>
                                        <a className="navfooter__link">Contact Us</a>
                                    </Link>
                                </div>
                                <div className="navfooter__item">
                                    <Link href="/knowledge-base" passHref>
                                        <a className="navfooter__link">Knowledge Base</a>
                                    </Link>
                                </div>
                                <div className="navfooter__item">
                                    <a className="navfooter__link" href="tel:888-994-5933">
                                        888.994.5933
                                    </a>
                                </div>
                            </div>
                            <div className="navfooter__col navfooter__col_fullwidth">

                            </div>
                        </div>
                    </div>
                    <div className="footer__extra footer__logo-social-container">
                        <div className="footer__general footer__logo-social">
                            <Link href="/" passHref>
                                <a className="logo footer__logo">
                                    <img className="logo__img" src="/resources/images/logo-main-white.svg" alt="Glyde Logotype" width="224" height="73" />
                                    <div className="footer__logo_custom" />
                                </a>
                            </Link>
                            <div className="sc">
                                {' '}
                                <a className="sc__link sc__link_fb" href="https://www.facebook.com/glydehome" rel="noreferrer" target="_blank" aria-label="Glyde in Facebook">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.74" height="27.521" viewBox="0 0 14.74 27.521" fill="#ff8d6d">
                                        <path
                                            data-name="Path 179"
                                            d="M13.774 15.48l.764-4.98H9.76V7.268a2.49 2.49 0 012.808-2.691h2.172V.337A26.477 26.477 0 0010.883 0C6.947 0 4.375 2.386 4.375 6.7v3.8H0v4.98h4.375v12.045H9.76V15.48z"
                                        ></path>
                                    </svg>
                                </a>
                                <a className="sc__link sc__link_ig" href="https://www.instagram.com/glydehome/" rel="noreferrer" target="_blank" aria-label="Glyde in Instagram">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30.806" height="30.805" viewBox="0 0 30.806 30.805" fill="#ff8d6d">
                                        <g data-name="Group 243">
                                            <g data-name="Group 242">
                                                <path
                                                    data-name="Path 176"
                                                    d="M23.559 5.5a1.806 1.806 0 101.806 1.806A1.806 1.806 0 0023.559 5.5z"
                                                ></path>
                                                <path
                                                    data-name="Path 177"
                                                    d="M15.529 7.816a7.587 7.587 0 107.586 7.586 7.6 7.6 0 00-7.586-7.586zm0 12.446a4.86 4.86 0 114.859-4.86 4.865 4.865 0 01-4.859 4.86z"
                                                ></path>
                                                <path
                                                    data-name="Path 178"
                                                    d="M21.552 30.806h-12.3a9.264 9.264 0 01-9.253-9.253v-12.3A9.264 9.264 0 019.252 0h12.3a9.264 9.264 0 019.254 9.253v12.3a9.264 9.264 0 01-9.254 9.253zM9.252 2.898a6.362 6.362 0 00-6.355 6.355v12.3a6.362 6.362 0 006.355 6.355h12.3a6.363 6.363 0 006.356-6.355v-12.3a6.363 6.363 0 00-6.356-6.355z"
                                                >
                                                    {' '}
                                                </path>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <a className="sc__link sc__link_tw" href="https://twitter.com/glydehome" rel="noreferrer" target="_blank" aria-label="Glyde in Twitter">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.922" height="22.981" viewBox="0 0 27.922 22.981" fill="#ff8d6d">
                                        <path
                                            data-name="Path 180"
                                            d="M27.807 2.754a11.529 11.529 0 01-2.824.8c.454-.077 1.121-.9 1.389-1.231a5.191 5.191 0 00.937-1.709c.025-.049.042-.11-.007-.147a.158.158 0 00-.149.012A14.423 14.423 0 0123.8 1.766a.225.225 0 01-.231-.061 2.726 2.726 0 00-.292-.3 5.914 5.914 0 00-1.622-.994 5.643 5.643 0 00-2.487-.4 5.942 5.942 0 00-2.36.666 6.081 6.081 0 00-1.9 1.555 5.833 5.833 0 00-1.137 2.277 6.16 6.16 0 00-.06 2.4c.018.135.006.153-.116.135-4.6-.685-8.409-2.33-11.5-5.855-.134-.153-.207-.153-.317.012-1.354 2.029-.7 5.29.994 6.892.226.214.458.427.707.622a5.865 5.865 0 01-2.22-.622c-.134-.085-.2-.036-.214.116a3.479 3.479 0 00.037.659 5.9 5.9 0 003.635 4.7 3.469 3.469 0 00.737.226 6.57 6.57 0 01-2.177.067c-.159-.031-.22.049-.159.2a6.123 6.123 0 004.575 3.83c.207.036.414.036.623.085-.013.019-.025.019-.037.037-.514.783-2.294 1.366-3.124 1.656a11.181 11.181 0 01-4.744.607c-.255-.038-.309-.034-.378 0s-.009.1.073.17c.324.214.653.4.989.586a15.633 15.633 0 003.172 1.269c5.7 1.574 12.12.418 16.4-3.834 3.361-3.339 4.539-7.944 4.539-12.555 0-.181.213-.281.34-.375a11.115 11.115 0 002.232-2.321.705.705 0 00.147-.444v-.024c-.004-.068-.005-.045-.118.006z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <div className="navextra">
                        <div className="navextra__item">
                            <Link href="/legal" passHref>
                                <a className="navextra__link">Terms Of Services</a>
                            </Link>
                        </div>
                        <div className="navextra__item">
                            <Link href="/legal" passHref>
                                <a className="navextra__link">Privacy Policy</a>
                            </Link>
                        </div>
                    </div>
                    <p className="footer__copyright">© 2022 Glyde, LLC All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default CustomFooter;
